<template>
    <ODataLookup :data-object="dsOrdersLkp">
        <OColumn field="ID" width="110"></OColumn>
        <OColumn field="Name" :headerName="$t('Name')" width="350"></OColumn>
        <OColumn field="TypeName" :headerName="$t('Type Name')" width="100"></OColumn>
        <OColumn field="ExternalReference" :headerName="$t('External Reference')" width="150"></OColumn>
        <template #target="scope" @click="scope.open">
            <button v-if="props.useAlternativeButton" :ref="scope.target" type="button"
                class="btn btn-link p-0 mt-2 text-decoration-none">
                <i class="bi bi-plus-circle p-2">&nbsp; Add Order</i>
            </button>
        </template>
    </ODataLookup>
</template>

<script setup lang="ts">
import { defineProps, onMounted, defineExpose } from 'vue'
import { getOrCreateDataObject } from 'o365.vue.ts';

const props = defineProps<{
  useAlternativeButton?: boolean
}>()

const dsOrdersLkp = getOrCreateDataObject({
    id: 'o_dsOrdersLkp',
    viewName: 'aviw_aSalesOrder_Orders',
    maxRecords: 100,
    whereClause: null,
    loadRecents: true,
    fields: [
        { name: "ID", type: "number", sortOrder: 1, sortDirection: "asc"  },
        { name: "IdPath", type: "string" },
        { name: "Name", type: "string" },
        { name: "ExternalReference", type: "string" },
        { name: "TypeName", type: "string" }
    ]
});

onMounted(() => {
    dsOrdersLkp.enableContextFilter();
})

defineExpose({
    dsOrdersLkp
})

</script>